<template>
  <div v-click-outside="() => toggleDropdown(true)" class="el-input-dropdown no-highlight" :class="{'--opened': isDropdownOpen, '--outlined': outlined !== false, '--error': validationError}" @click="toggleDropdown()">
    <label v-if="label" class="__dropdown-label">{{ label }}</label>
    <div class="__input-dropdown-inner">
      <div class="__selection">
        {{ selectedFullItem ? selectedFullItem.label : overridePlaceholder }}
      </div>
      <div class="__inner-icon">
        <img :src="downArrowIcon" alt="Down arrow" data-not-lazy width="14" height="14">
      </div>
    </div>

    <div class="__dropdown-items-wrapper">
      <ul class="__dropdown-items">
        <li v-for="(item, index) in items" :key="`item-${index}`" @click="updateSelectedItem(item)">
          {{ item.label }}
        </li>
      </ul>
    </div>
    <transition name="fade">
      <span v-if="validationError" :key="`input-error-message`" class="el-input-text__validation-error">{{ validationError }}</span>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {computed, ComputedRef, PropType, Ref} from 'vue';
import {DropdownItem} from '~/@types/common';
import vClickOutside from '~/composables/clickOutside';
import downArrowIcon from '~/assets/images/icons/down-arrow.webp';
import validationRules, {AppliedRules} from '~/composables/validationRules';

// Data
const isDropdownOpen: Ref<boolean> = ref(false);

// PROPS
const props = defineProps({
  items: {
    type: Object as PropType<DropdownItem[]>,
    required: true,
  },
  returnFullObject: {
    type: Boolean,
    default: false,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [Number, String, Object] as PropType<any>,
    default: null,
  },
  overridePlaceholder: {
    type: String,
    default: 'Select',
  },
  rules: {
    type: [Object, null] as PropType<{fieldName: string, rules: AppliedRules}>,
    default: null,
  },
  isValid: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

const validationError: Ref<string | null> = ref(null);
const inputValue: Ref<number | string | object | null> = ref(props.modelValue);

// EMIT Definitions
const emit = defineEmits<{
  (event: 'update:modelValue', payload: DropdownItem | string | number | object | null): void;
  (event: 'change'): void;
  (event: 'update:isValid', payload: string[]): void;
}>();

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  inputValue.value = props.modelValue;
});

/**
 * @_WATCH modelValue
 */
watch(() => props.modelValue, () => {
  if (props.modelValue === inputValue.value) {
    return;
  }
  inputValue.value = props.modelValue;
});


/**
 * Return value or full item object
 */
const selectedFullItem: ComputedRef<DropdownItem | null> = computed(() => {
  if (props.returnFullObject === false) {
    return props.items.find((item: DropdownItem) => item.value === props.modelValue);
  }
  return props.modelValue;
});

/**
 * Handle item clicked event
 *
 * @param clickedItem
 */
function updateSelectedItem (clickedItem: DropdownItem) {
  updateModelValue(clickedItem);
  emit('change');
}

/**
 * @_WATCH isValid
 */
watch(() => props.isValid, () => {
  validateInput();
  if (validationError.value?.length) {
    const localIsValid = props.isValid;
    localIsValid?.push(validationError.value);
    emit('update:isValid', localIsValid);
  }
});

/**
 * Validate input
 */
function validateInput () {
  if (props.rules?.rules && props.rules?.fieldName) {
    validationError.value = validationRules.rules(props.rules?.fieldName, props.rules?.rules, inputValue.value);
  }
}

/**
 * Update model value
 *
 * @param selectedItem
 */
function updateModelValue (selectedItem: DropdownItem) {
  inputValue.value = selectedItem.value;
  emit('update:modelValue', props.returnFullObject !== false ? selectedItem : selectedItem.value);
}

/**
 * Toggle dropdown
 */
function toggleDropdown (forcedToClose = false) {
  if (forcedToClose) {
    isDropdownOpen.value = false;
  } else {
    isDropdownOpen.value = !isDropdownOpen.value;
  }
}

</script>

<style lang="scss" scoped>
.el-input-dropdown {
  width: 100%;
  min-height: 50px;
  height: fit-content;
  position: relative;
  padding-bottom: 12px;

  .__input-dropdown-inner {
    width: 100%;
    min-height: 45px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;

    .__selection {
      color: rgba(var(--text-lighter-1), 0.8);
      white-space: nowrap;
      padding-right: 12px;
    }

    .__inner-icon {
      transform: rotate(0deg);
      transform-origin: center center;
      transition: all 0.2s ease-in;

      img {
        width: 14px;
        height: auto;
      }
    }

    &:hover {
      .__selection {
        color: rgba(var(--text-lighter-1), 1);
      }
    }
  }

  .__dropdown-items-wrapper {
    max-height: 0;
    height: fit-content;
    min-width: 100%;
    overflow: hidden;
    background: rgb(var(--background-light));
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-top: 0;
    box-shadow: 0 0 8px -2px #b4b4b4;
    position: absolute;
    width: fit-content;
    z-index: 999;

    .__dropdown-items {
      padding: 8px 0;

      li {
        padding: 15px 12px;
        cursor: pointer;
        white-space: nowrap;
        background-color: #fff;
        font-weight: 300;
        transition: background-color 0.2s ease-in;

        &:hover {
          background-color: #f1f1f1;
          transition: background-color 0.2s ease-in;
        }
      }
    }
  }

  &.--opened {
    .__input-dropdown-inner {
      .__inner-icon {
        transform: rotate(180deg);
        transition: all 0.2s ease-in;
      }
    }

    .__dropdown-items-wrapper {
      max-height: 218px;
      overflow: auto;
      transition: max-height 0.4s cubic-bezier(.79,.14,.15,.86);
    }
  }

  &.--outlined {
    .__input-dropdown-inner {
      border: 1px solid rgba(var(--text-lighter-1), 0.3);
      border-radius: 5px;
    }
  }

  .__dropdown-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: rgb(var(--text-default));
  }

  // Checkbox styles
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  .el-input-text__validation-error {
    display: block;
    color: #d30101;
    font-size: 12px;
    padding-left: 12px;
    padding-top: 5px;
    margin-top: -5px;
  }
}
</style>
