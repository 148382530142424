/**
 * Click outside function
 *  - This is to detect when click out-side of the given element
 */
const vClickOutside = {
  mounted: (el: HTMLElement, binding: any) => {
    (el as any).clickOutsideEvent = function(event: any) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', (el as any).clickOutsideEvent);
  },
};

export default vClickOutside;
